import { initLoggers } from 'lib/loggers';

initLoggers();
//

import { HKEStyleProvider } from '@hkexpressairwayslimited/ui';
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter';
import { SitecorePageProps } from 'lib/page-props';
import { I18nProvider } from 'next-localization';
import type { AppProps } from 'next/app';

// Using bootstrap and nprogress are completely optional.
//  bootstrap is used here to provide a clean layout for samples, without needing extra CSS in the sample app
//  nprogress provides a loading indicator on page/route changes
// Remove these in package.json as well if removed here.
import 'assets/main.scss';
// UI style
import '@hkexpressairwayslimited/ui/styles';
import { DictionaryPhrases } from '@sitecore-jss/sitecore-jss-nextjs';
import { useMemo } from 'react';
import Bootstrap from 'src/Bootstrap';

function App(props: AppProps<SitecorePageProps>): JSX.Element {
  const { Component, pageProps } = props;
  const { dictionary, ...rest } = pageProps;

  const newDict: DictionaryPhrases = useMemo(() => {
    const dict = dictionary || {};
    try {
      if (dict.CONTENT_JSON_WEB) {
        const contentJson = JSON.parse(dict.CONTENT_JSON_WEB);

        Object.assign(dict, contentJson);
        delete dict.CONTENT_JSON_WEB;
      }
    } catch (err) {
      console.log('Error parsing content JSON', err);
    }

    return Object.entries(dict).reduce(
      (obj, [key, value]) => ({
        ...obj,
        [key.replaceAll('.', '')]: value,
      }),
      {}
    );
  }, [dictionary]);

  return (
    <>
      <AppCacheProvider {...props}>
        <HKEStyleProvider>
          <Bootstrap {...pageProps} />
          {/*
        // Use the next-localization (w/ rosetta) library to provide our translation dictionary to the app.
        // Note Next.js does not (currently) provide anything for translation, only i18n routing.
        // If your app is not multilingual, next-localization and references to it can be removed.
      */}
          <I18nProvider lngDict={newDict} locale={pageProps.locale}>
            <Component {...rest} />
          </I18nProvider>
        </HKEStyleProvider>
      </AppCacheProvider>
    </>
  );
}

export default App;
